import React, {Component} from 'react';
import {connect} from 'react-redux';
import './Generator.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../handlebars-helpers/helpers';

import Editor from '../containers/Editor';
import Newsletter from '../containers/Newsletter';

import SendTestModal from '../components/Modals/SendTestModal';
import SaveModal from '../components/Modals/SaveModal';
import ExportAsHtmlModal from '../components/Modals/ExportAsHtmlModal';
import SaveToPostUpModal from '../components/Modals/SaveToPostUpModal';


import {
    toggleTestModal,
    updateEmailAddress,
    updateSubject,
    sendTestEmail,
    exitTestModal,
} from '../actions/test-email-actions';

import {
    toggleSaveModal,
    exitSaveModal,
    saveNewsletter,
    updateNewsletter,
} from '../actions/save-newsletter-actions';

import {
    exitExportModal,
    toggleExportModal,
    htmlFileNameChange,
    exportHtmlFile,
} from '../actions/export-as-html-actions';

import {
    toggleSaveToPostUpModal,
    exitSaveToPostUpModal,
    changePostUpSubject,
    saveToPostUp, changePostUpTitle,
} from '../actions/save-to-post-up-actions';

import {fetchPrevNewsletter} from '../actions/editor-actions';
import SaveToEmailOnAcid from "../components/Modals/SaveToEmailOnAcid";
import {
    changeEmailOnAcidSubject,
    exitSaveToEmailOnAcidModal, saveToEmailOnAcid,
    toggleSaveToEmailOnAcidModal
} from "../actions/save-to-email-on-acid";
import {initInternalApi} from "../newsletter-api/init";

class Generator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    /**
     * Fired when component loads -- all async tasks
     * @returns {Promise<void>}
     */
    async componentDidMount() {
        try {
            this.setState({isLoading: true})
            const api = initInternalApi();
            const brandsPromise = api.fetchBrandNames();
            const templatesPromise = api.fetchTemplates();

            const brands = await brandsPromise;
            const templates = await templatesPromise;

            this.props.dispatch({type: 'SET_TEMPLATES', templates});
            this.props.dispatch({type: 'SET_BRANDS', brands});

            const urlParams = new URLSearchParams(window.location.search);
            const strId = urlParams.get('id');
            if (strId !== null) {
                const id = Number(strId);
                if (isNaN(id) || id <= 0) return;
                this.props.fetchPrevNewsletter(id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({isLoading: false})
        }
    }

    /**
     * Creates the HTML Document
     * @returns {string}
     */
    createHTMLDoc = async (shouldMinify) => {
        const {
            templateStyle,
            previewText,
            hbsTmplHeaderFunc,
            templateData,
            hbsTmplFunc,
            tagline,
            releaseDate,
            footers,
            footerId
        } =
            this.props.newsletter;
        const {brand} = this.props;
        const footer = footers.find(f => f.id === footerId)

        // Currently we are not adding in any extra data, so we dont have to move this...
        const header = hbsTmplHeaderFunc();
        const newTemplateData = {
            ...templateData,
            brand: {
                color: brand.color,
                secondaryColor: brand.secondaryColor,
                darkImageUrl: brand.darkLogoUrl,
                glowLogoUrl: brand.glowLogoUrl,
                imageUrl: brand.logoUrl,
                landingUrl: brand.homeUrl,
                alt: brand.name,
                tagline: tagline,
                updateProfile: brand.updateProfile,
            },
            releaseDate,
            footer: {
                editorName: footer?.editorName,
                editorEmail: footer?.editorEmail,
                mediaGuideUrl: footer?.mediaGuideUrl,
                imageUrl: footer?.imageUrl,
                landingUrl: footer?.landingUrl,
                subscriptionUrl: footer?.subscriptionUrl
            },
        };

        const body = hbsTmplFunc(newTemplateData);
        let preview = '<span></span>';
        if (previewText !== '') {
            preview = `
              <div style="display: none; max-height: 0px; overflow: hidden;">
                  ${previewText}
              </div>
            `;
        }

        const fullDoc = `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html
                xmlns="http://www.w3.org/1999/xhtml"
                xmlns:v="urn:schemas-microsoft-com:vml"
                xmlns:o="urn:schemas-microsoft-com:office:office"
                lang="en">
                <head>
                    ${header}
                    <style>
                    ${templateStyle}
                    </style>
                </head>
                <body>
                    ${preview}
                    ${body}
                </body>
            </html>
        `;

        if (shouldMinify) {
            const api = initInternalApi();
            const res = await api.minifyHtml(fullDoc)
            return res.minified;
        }

        return fullDoc;
    };

    /**
     * Sends the corresponding newsletter to the appropriate mailing list
     * to PostUp
     */
    saveToPostUp = async (e) => {
        e.preventDefault();
        const htmlDoc = await this.createHTMLDoc(true);
        const {subject, title} = this.props.postUp;
        const {brandId, mailingListId, releaseDate} = this.props.newsletter;

        this.props.saveToPostUp(
            subject,
            brandId,
            mailingListId,
            releaseDate,
            htmlDoc,
            title,
        );
    };

    saveToEmailOnAcid = async (e) => {
        e.preventDefault();
        const htmlDoc = await this.createHTMLDoc(true);
        const {subject} = this.props.emailOnAcid;
        this.props.saveToEmailOnAcid(subject, htmlDoc);
    }

    /**
     * Exports the current newsletter as HTML
     */
    exportAsHTMLMinify = async (e) => {
        e.preventDefault();
        await this.exportAsHTML(true);
    };

    exportAsHTMLNonMinify = async (e) => {
        e.preventDefault();
        await this.exportAsHTML(false);
    };

    exportAsHTML = async (shouldMinify) => {
        this.props.exportHtmlClick();
        let {fileName} = this.props.htmlExport;
        if (fileName === '') {
            const today = new Date();
            const day = today.getDate().toString();
            const month = (today.getMonth() + 1).toString();
            const year = today.getFullYear().toString();

            fileName = `${month}-${day}-${year}-nlg.html`;
        }

        const hasHtmlExt = fileName.slice(fileName.length - 4) === 'html';
        if (!hasHtmlExt) fileName = `${fileName}.html`;

        const htmlDoc = await this.createHTMLDoc(shouldMinify);

        const link = document.createElement('a');
        const mimeType = 'text-plain';
        link.setAttribute('download', fileName);
        link.setAttribute(
            'href',
            `data: ${mimeType};charset=utf-8,${encodeURIComponent(htmlDoc)}`,
        );
        link.click();
        link.remove();
    };

    saveNewsletter = (e) => {
        e.preventDefault();
        const {releaseDate, brandId, templateId, tagline, templateData, footerId} =
            this.props.newsletter;

        this.props.saveNewsletter(
            releaseDate,
            brandId,
            templateId,
            tagline,
            templateData,
            footerId
        );
    };

    updateNewsletter = (e) => {
        e.preventDefault();
        const {
            brandId,
            templateId,
            tagline,
            templateData,
            prevNewsletterId,
            releaseDate,
            footerId
        } = this.props.newsletter;
        this.props.updateNewsletter(
            releaseDate,
            brandId,
            templateId,
            tagline,
            templateData,
            prevNewsletterId,
            footerId
        );
    };

    sendTestEmail = async (e) => {
        e.preventDefault();
        const {emailAddress, subject} = this.props.testEmail;
        const newsletterHTML = await this.createHTMLDoc();
        this.props.sendTestEmail(emailAddress, subject, newsletterHTML);
    };

    render() {
        if (this.state.isLoading) return <div>Loading...</div>
        return (
            <>
                <SendTestModal
                    modalOpen={this.props.testEmail.modalOpen}
                    testSubject={this.props.testEmail.subject}
                    testEmailAddress={this.props.testEmail.emailAddress}
                    toggleModal={this.props.toggleTestModal}
                    sending={this.props.testEmail.sending}
                    message={this.props.testEmail.message}
                    changeTestEmailAddress={this.props.updateTestEmailAddress}
                    changeTestSubject={this.props.updateTestSubject}
                    sendTestEmail={this.sendTestEmail}
                    exitModal={this.props.exitTestModal}
                />

                <SaveModal
                    newsletterId={this.props.newsletter.prevNewsletterId}
                    modalOpen={this.props.save.modalOpen}
                    message={this.props.save.message}
                    saving={this.props.save.saving}
                    save={this.saveNewsletter}
                    update={this.updateNewsletter}
                    exitModal={this.props.exitSaveModal}
                    toggleModal={this.props.toggleSaveModal}
                />

                <SaveToPostUpModal
                    modalOpen={this.props.postUp.modalOpen}
                    subject={this.props.postUp.subject}
                    title={this.props.postUp.title}
                    saving={this.props.postUp.saving}
                    message={this.props.postUp.message}
                    exitModal={this.props.exitSaveToPostUpModal}
                    toggleModal={this.props.toggleSaveToPostUpModal}
                    changeSubject={this.props.changePostUpSubject}
                    changeTitle={this.props.changePostUpTitle}
                    saveToPostUp={this.saveToPostUp}
                />

                <SaveToEmailOnAcid
                    modalOpen={this.props.emailOnAcid.modalOpen}
                    subject={this.props.emailOnAcid.subject}
                    saving={this.props.emailOnAcid.saving}
                    message={this.props.emailOnAcid.message}
                    exitModal={this.props.exitSaveToEmailOnAcidModal}
                    toggleModal={this.props.toggleSaveToEmailOnAcidModal}
                    changeSubject={this.props.changeEmailOnAcidSubject}
                    saveToEmailOnAcid={this.saveToEmailOnAcid}
                />

                <ExportAsHtmlModal
                    modalOpen={this.props.htmlExport.modalOpen}
                    htmlFileName={this.props.htmlExport.fileName}
                    exporting={this.props.htmlExport.exporting}
                    htmlFileNameChange={this.props.htmlFileNameChange}
                    exitModal={this.props.exitExportModal}
                    toggleModal={this.props.toggleExportModal}
                    exportHTMLClick={this.exportAsHTMLNonMinify}
                    exportHTMLMinifyClick={this.exportAsHTMLMinify}
                />

                {/**
                 * Rendered Editor
                 */}

                <Editor/>


                {/**
                 * Newsletter iFrame
                 * Reasoning for iFrame was to encapsulate style inputs away from bootstrap
                 * framework and other random styles applied
                 * we could also hard style the template itself too by passing in the css object without
                 * worrying it will affect the editor or other parts of the application
                 */}
                <Newsletter/>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        TestEmailReducer,
        SaveNewsletterReducer,
        ExportAsHtmlReducer,
        SaveToPostUpReducer,
        NewsletterReducer,
        SaveToEmailOnAcidReducer
    } = state;

    const {brandId, brands} = NewsletterReducer;
    let brand = {};
    if (brandId > 0) brand = brands.find((brand) => brand.id === brandId);

    return {
        testEmail: TestEmailReducer,
        save: SaveNewsletterReducer,
        htmlExport: ExportAsHtmlReducer,
        postUp: SaveToPostUpReducer,
        newsletter: NewsletterReducer,
        emailOnAcid: SaveToEmailOnAcidReducer,
        brand: brand
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        fetchPrevNewsletter: (newsletterId) =>
            dispatch(fetchPrevNewsletter(newsletterId)),
        toggleSaveToEmailOnAcidModal: () => dispatch(toggleSaveToEmailOnAcidModal()),
        exitSaveToEmailOnAcidModal: () => dispatch(exitSaveToEmailOnAcidModal()),
        changeEmailOnAcidSubject: (e) => {
            const {target} = e;
            const {value: subject} = target;
            dispatch(changeEmailOnAcidSubject(subject));
        },
        saveToEmailOnAcid: (subject, htmlDoc) => {
            dispatch(saveToEmailOnAcid(subject, htmlDoc));
        },
        toggleSaveToPostUpModal: () => dispatch(toggleSaveToPostUpModal()),
        exitSaveToPostUpModal: () => dispatch(exitSaveToPostUpModal()),
        changePostUpSubject: (e) => {
            const {target} = e;
            const {value: subject} = target;
            dispatch(changePostUpSubject(subject));
        },
        changePostUpTitle: (e) => {
            const {target} = e;
            const {value: title} = target;
            dispatch(changePostUpTitle(title));
        },
        saveToPostUp: (title, subject, brandId, listId, releaseDate, htmlDoc) =>
            dispatch(
                saveToPostUp(title, subject, brandId, listId, releaseDate, htmlDoc),
            ),
        toggleExportModal: () => dispatch(toggleExportModal()),
        exitExportModal: () => dispatch(exitExportModal()),
        toggleTestModal: () => dispatch(toggleTestModal()),
        htmlFileNameChange: (e) => dispatch(htmlFileNameChange(e)),
        exportHtmlClick: () => dispatch(exportHtmlFile()),
        updateTestEmailAddress: (e) => {
            const {target} = e;
            const {value} = target;
            dispatch(updateEmailAddress(value));
        },
        updateTestSubject: (e) => {
            const {target} = e;
            const {value} = target;
            dispatch(updateSubject(value));
        },
        sendTestEmail: (emailAddress, subject, htmlDocument) =>
            dispatch(sendTestEmail(emailAddress, subject, htmlDocument)),
        exitTestModal: () => dispatch(exitTestModal()),
        toggleSaveModal: () => dispatch(toggleSaveModal()),
        exitSaveModal: () => dispatch(exitSaveModal()),
        saveNewsletter: (releaseDate, brandId, templateId, tagline, context, footerId) =>
            dispatch(
                saveNewsletter(releaseDate, brandId, templateId, tagline, context, footerId),
            ),
        updateNewsletter: (
            releaseDate,
            brandId,
            templateId,
            tagline,
            context,
            newsletterId,
            footerId
        ) =>
            dispatch(
                updateNewsletter(
                    releaseDate,
                    brandId,
                    templateId,
                    tagline,
                    context,
                    newsletterId,
                    footerId
                ),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Generator);
